<template>
  <div class="competition">
    <banner />
    <div class="specia-list">
      <div class="content">
        <div class="c-title anime">
          评审专家团
          <span></span>
        </div>
        <div class="list">
          <div class="item anime" v-for="item in yz_vote_experts" :key="item.id">
            <el-image
              :src="item.remarks"
              fit="cover"
            ></el-image>
            <h3>{{item.dictValue}}</h3>
            <p>{{item.dictLabel}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from "./components/banner";
export default {
  components: { banner },
  data() {
    return {
      yz_vote_experts:[]
    };
  },
  mounted() {
    this.$sapi.get("/api/v1/getDictDataList-yz_vote_experts.json").then((res) => {
      this.yz_vote_experts = res;
    });
    this.$parent.finish ? this.$parent.finish() : null;
  },
};
</script>
<style lang="scss" scoped>
.competition {
  color: white;
  .c-title {
    text-align: center;
    font-size: 30px;
    color: #fffefe;
    span {
      width: 61px;
      height: 4px;
      display: block;
      background-color: $c;
      box-shadow: 0 0 15px 5px fade-out($color: $c2, $amount: 0.5);
      margin: 20px auto 0;
    }
  }
  .text-item {
    margin-top: 5vw;
    .content {
      .desc {
        margin-top: 4vw;
        font-size: 20px;
        line-height: 3vw;
        letter-spacing: 2px;
        &.c {
          text-align: center;
        }
      }
    }
  }
  .specia-list {
    margin-top: 5vw;
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 4vw;
      .item {
        width: 23%;
        margin: 3% 1%;
        text-align: center;
        .el-image {
          width: 270px;
          height: 270px;
          border-radius: 50%;
        }
        h3 {
          margin-top: 25px;
          font-size: 18px;
        }
        p {
          margin-top: 20px;
          font-size: 18px;
        }
      }
    }
  }
}
</style>